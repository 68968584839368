import React from 'react';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import styles from './EOB.css';
import EOB from './Assets/EOB.png';
import DeveloperImage from './Assets/IOXDeveloper.png';

export default function Patient(){
let Patientresponse={
    "resourceType": "Patient",
    "id": "200000400",
    "meta": {
        "lastUpdated": "2021-10-07T12:25:02.970+00:00",
        "profile": [
            "http://hl7.org/fhir/us/carin-bb/StructureDefinition/C4BB-Patient"
        ],
        "security": [
            {
                "system": "http://terminology.hl7.org/CodeSystem/v3-Confidentiality",
                "code": "U",
                "display": "unrestricted"
            }
        ]
    },
    "language": "en-US",
    "extension": [
        {
            "url": "http://hl7.org/fhir/us/core/StructureDefinition/us-core-birthsex",
            "valueCode": "F"
        },
        {
            "url": "http://hl7.org/fhir/us/core/StructureDefinition/us-core-race",
            "extension": [
                {
                    "url": "text",
                    "valueString": "White"
                },
                {
                    "url": "ombCategory",
                    "valueCoding": {
                        "system": "urn:oid:2.16.840.1.113883.6.238",
                        "code": "2106-3",
                        "display": "White"
                    }
                }
            ]
        }
    ],
    "identifier": [
        {
            "type": {
                "coding": [
                    {
                        "system": "http://terminology.hl7.org/CodeSystem/v2-0203",
                        "code": "RI",
                        "display": "Patient Id"
                    }
                ]
            },
            "system": "http://terminology.hl7.org/CodeSystem/v2-0203",
            "value": "200000400"
        },
        {
            "type": {
                "coding": [
                    {
                        "system": "http://terminology.hl7.org/CodeSystem/v2-0203",
                        "code": "MB"
                    }
                ]
            },
            "system": "http://terminology.hl7.org/CodeSystem/v2-0203",
            "value": "200000400"
        },
        {
            "type": {
                "coding": [
                    {
                        "system": "http://terminology.hl7.org/CodeSystem/v2-0203",
                        "code": "SS"
                    }
                ]
            },
            "system": "http://terminology.hl7.org/CodeSystem/v2-0203",
            "value": "XXXXX0400"
        },
        {
            "type": {
                "coding": [
                    {
                        "system": "http://hl7.org/fhir/us/carin-bb/CodeSystem/C4BBIdentifierType",
                        "code": "um"
                    }
                ]
            },
            "system": "http://hl7.org/fhir/us/carin-bb/CodeSystem/C4BBIdentifierType",
            "value": "200000400"
        }
    ],
    "name": [
        {
            "family": "Saini",
            "given": [
                "Piyush",
                "M"
            ]
        }
    ],
    "telecom": [
        {
            "system": "phone",
            "value": "4854PHONE",
            "use": "home"
        }
    ],
    "gender": "male",
    "birthDate": "1997-01-01",
    "address": [
        {
            "line": [
                "4854ADD1",
                "4854ADD2"
            ],
            "city": "4854CITY",
            "district": "Madison",
            "state": "MO",
            "postalCode": "64844",
            "country": "USA"
        }
    ],
    "communication": [
        {
            "language": {
                "coding": [
                    {
                        "system": "urn:ietf:bcp:47",
                        "code": "not known",
                        "display": "not known"
                    }
                ]
            },
            "preferred": false
        }
    ]
};
const codestyle={backgroundColor: 'lightgray'};
const height={height:"120px"};
return (
<div className="container">
<Card className="card-background"> <Card.Body>
<blockquote className="blockquote mb-0">
<h1 className="text-header">Patient</h1>
<p>The IOX Patient API implementation uses the CARIN for Blue Button (C4BB) Patient profile.</p>
</blockquote></Card.Body></Card>
<br/>
<hr className="horizontal-rule"></hr>
<br/>

<Card className="card-background"> <Card.Body>
<blockquote className="blockquote mb-0">
<p>For more information, see the HL7 <a href="http://hl7.org/fhir/us/carin-bb/STU1/CapabilityStatement-c4bb.html">C4BB Capability Statement.</a>
</p>
<h1>Querying Patient-related Resources in the IOX System</h1>
<p>The IOX system lets you search Patient-related information using Fast Healthcare Interoperability Resources (FHIR) resource types to retrieve data.</p>
<p> For additional information on how searches work, see HL7 FHIR Specification sections  <a href="https://www.hl7.org/fhir/http.html">3.1.0.9 search </a> and <a href="https://www.hl7.org/fhir/search.html">3.1.1 Search.</a></p>
<h1>Patient API Query Strings </h1>
<p> The Patient API – Query Details Table and the Clinical API – Query Details Table below list available query strings and describe key parameters that make up the queries.</p>
<p>For example, to retrieve patient details of '561234' for the category of Explanation of Benefits, you would use the query string in the image below. Enter the query by</p>
 <p>updating the value segment of the query string with the value you want to locate.</p>
<img class="img-responsive" src={EOB} alt="any text"></img>
<p>Includes and Reverse Includes </p>
<p> Many query strings incorporate contain (_include) and reverse include (_revinclude) parameters to determine which resources a search draws upon.
    The HL7 specification states the following about includes and reverse includes:</p>
<p>The client can use the _include parameter to indicate that the subject resources be included in the results. An alternative scenario _revinclude is</p>
<p> where the client wishes to fetch a resource and any resources that refer to it. For example, the client may wish to fetch a MedicationRequest and any provenance resources that refer to the prescription. This is known as a reverse include and is specified by providing a _revinclude parameter.</p>
<p><a href="https://www.hl7.org/fhir/search.html">Source: Section 3.1.1.5.4</a> of the HL7 FHIR Specification.</p>
<p>Related HL7 FHIR Specifications  </p>
<ol className="EOB-list">
<li>To learn more about how searches work, see HL7 FHIR Specification sections <a href="https://www.hl7.org/fhir/http.html#search">3.1.0.9 search</a> and <a href="https://www.hl7.org/fhir/search.html#3.1.1">3.1.1 Search</a></li>
<li>To find details about specific resources and parameters, see section <a href="https://www.hl7.org/fhir/searchparameter-registry.html">3.1.4</a> of the HL7 FHIR Specification.</li>
</ol>
<h1>Patient API – Query Details Table </h1>
<p>The following table lists details about query strings for the IOX Patient API and the elements that make up the queries.</p>
<p>The information in this table derives from the <a href="https://www.hl7.org/fhir/index.html">HL7 specifications </a>, including the C4BB Capability Statement (CARIN Blue Button Implementation Guide, <a href="http://hl7.org/fhir/us/carin-bb/STU1/CapabilityStatement-c4bb.html">section 10.1.2</a> ) and FHIR Specification Defined Search Parameters (<a href="https://www.hl7.org/fhir/searchparameter-registry.html">section 3.1.4</a>).</p>
<img class="img-responsive" src={DeveloperImage} alt="any text"></img>

</blockquote> </Card.Body>
</Card>

<br/>
<hr className="horizontal-rule"></hr>
<br/>

<Card className="card-background">
<Card.Body>
<h4 className="EOB-heading">URL</h4>
<div>
<codeblock className="accesstoken-code"> URL</codeblock>
<span>&nbsp;</span><span>&nbsp;</span>
<codeblock className="accesstoken-code">{process.env.REACT_APP_EDUCATIONAL_MATERIAL_URL}patientAccess/api/R4/Patient</codeblock>
</div>
</Card.Body>
<Card.Body>
<h4 className="EOB-heading">Headers</h4>
<Table striped bordered hover>
<tbody>

<tr>
<td>Accept</td>
<td>application/json</td>
</tr>
<tr> <td>Authorization</td>
<td>Bearer 'ACCESS_TOKEN'</td>
</tr>
</tbody>
</Table>
</Card.Body>
<p>Note:Third party app will have access token after member has given consent to app (Refer Member consent section)
</p>
<Card.Body>
<h4 className="EOB-heading">Sample Request</h4>

<div style={codestyle}>'curl --location 'https://iox.dev.sddss.apps.conduent.com/patientAccess/api/R4/Patient/202227102''\<br/>
                                    '--header 'Authorization: Bearer 7Sp6WPj9AcmSLJMD_6u-bVZHp-4' '\<br/>
                                     '--header 'Cookie: JSESSIONID=bp4uCPJH3YbKA9eGuXDDh380zbq1oFPOuE9NsWNi' '\
</div>

<h4 className="EOB-heading">Sample Response</h4>

<pre style={codestyle}>
<code class="prettyprint">
{JSON.stringify(Patientresponse,null,'  ')}
</code>
</pre>
</Card.Body>



</Card>
</div>
);

}